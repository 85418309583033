@import '../../../resources/theme/Common.scss';

.btn-container {
  height: $btn-height;
  width: 100%;
  border-radius: $btn-radius;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  box-shadow: $btn-shadow;
  border: none;
}

.btn-mainPrefab {
  background: $btn-main;
}

.btn-subPrefab {
  background: $btn-sub;
}

.btn-disabled {
  background: $btn-disabled;
  border: $btn-disabled-border;
}

.btn-icon {
  height: 45px;
  width: 45px;
  margin: 0 10px 0 0;
}

.btn-rightIcon {
  position: absolute;
  right: 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

.btn-deco {
  position: absolute;
  height: 3px;
  width: 90%;
  background: $btn-deco;
  transform: rotate(-2deg);
}

.btn-textStyle {
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  color: $btn-txt;
}

.btn-disabled-textStyle {
  color: $btn-disabled-txt;
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .btn-textStyle {
    font-size: 16px;
  }

  .btn-container {
    height: $btn-mobile-height;
  }

  .btn-icon {
    height: 30px;
    width: 30px;
    margin: 0 10px 0 0;
  }
}

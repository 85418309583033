.theme-blueMode-default {
    // Common
    --main-shadow: 3px 3px 4px 0px #131617;
    --noData-txt: #ffffff;
    
    // CPListTab
    --listTab: #141723;
    --list-txt: #ffffff;
    --list-unSelect-text: #C9C9C9;
    --list-underline: linear-gradient(176deg, #00D8FF 3.44%, #0378FF 96.71%);

    //#region =====> Porn
    --porn-content-bg: #002652;
    --porn-title: #FFFFFF;
    --porn-date: #FFFFFF;
    --porn-desc: #FFFFFF;
    //#endregion

    //#region =====> Movie
    --movie-content-bg: #002652;
    --movie-imdb-rate: #ffeb00;
    --movie-btn-bg: linear-gradient(176deg, #00D8FF 3.44%, #0378FF 96.71%);
    --movie-btn-txt: #ffffff;
    --movie-detail-bg: #ffffff;
    --movie-bot-border: 1px solid rgba(0, 0, 0, 0.12);
    --movie-rate-blue: #1a0dab;
    --movie-google-users-txt: #70757a;
    --movie-desc: #4d5156;
    --movie-title: #ffffff;
    --movie-date: #ffffff;
    --movie-imdb: #ffffff;
    --movie-popup-title: #ffffff;
    --movie-imdb-title: #ffffff;
    //#endregion

    //Plane
    --plane-bg: rgba(0, 0, 0, 0.25);
    --plane-blur: blur(10px);

    // CPButton
    --btn-main: linear-gradient(92.15deg, #34BEFD 0.35%, #AB00FE 98.37%);
    --btn-sub: linear-gradient(to right, #d7437c, #f6b56b);
    --btn-disabled: linear-gradient(92.15deg, #225F92 0.35%, #560B92 98.37%);
    --btn-disabled-border: none;
    --btn-disabled-txt: #707070;
    --btn-shadow: var(--main-shadow);
    --btn-deco: var(--main-bg);
    --btn-txt: var(--main-txt);
    --btn-border-radius: 10px;
    --btn-radius: 10px;
    --btn-font: 20px;
    --btn-height: 48px;
    --btn-mobile-height: 35px;
    --btn-width: 280px;

    //error modal
    --errorModal-bg: #FDF0E3;
    --errorModal-border: 1px solid #707070;
    --errorModal-title-txt: #5F5D60;
    --errorModal-content-txt: #5F5D60;
    --errorModal-btn-txt: #ffffff;
    --errorModal-btn-bg: linear-gradient(180deg, #FC8165 0%, #FC516A 100%)
}
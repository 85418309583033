@import '../../../resources/theme/Common.scss';

.content-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 0px;
  // background: $plane-bg;
  // backdrop-filter: $plane-blur;
  display: flex;
  overflow: hidden;
  animation: contentModalAnim 0.5s ease-out;
  z-index: 1;
}

.content-modal-content {
  width: 100%;
  height: 100%;
  padding: 24px 18px;
  overflow: auto;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {

}

@keyframes contentModalAnim {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.theme-light {
    // Common
    --main-shadow: 4px 4px 4px 0px rgba(252, 81, 106, 0.30);
    --noData-txt: #000000;
    
    // CPListTab
    --listTab: #FDF0E3;
    --list-txt: #000000;
    --list-unSelect-text: #707070;
    --list-underline: linear-gradient(180deg, #FC8165 0%, #FC516A 100%);

    //#region =====> Porn
    --porn-content-bg: linear-gradient(135deg, #f7bb6a 0%, #d3367e 100%);
    --porn-title: #000000;
    --porn-date: #000000;
    --porn-desc: #000000;
    //#endregion

    //#region =====> Movie
    --movie-content-bg: linear-gradient(132.89deg, #cb2ab8 3.42%, #2700c9 100%);
    --movie-imdb-rate: #ffeb00;
    --movie-btn-bg: #ffffff;
    --movie-btn-txt: #202124;
    --movie-detail-bg: #ffffff;
    --movie-bot-border: 1px solid rgba(0, 0, 0, 0.12);
    --movie-rate-blue: #1a0dab;
    --movie-google-users-txt: #70757a;
    --movie-desc: #4d5156;
    //#endregion

    //Plane
    --plane-bg: transparent;
    --plane-blur: blur(10px);

    // CPButton
    --btn-main: linear-gradient(180deg, #FC8165 0%, #FC516A 100%);
    --btn-sub: linear-gradient(180deg, #FC8165 0%, #FC516A 100%);
    --btn-disabled: #707070;
    --btn-disabled-border: var(--main-border);
    --btn-disabled-txt: #BEBDBF;
    --btn-shadow: none;
    --btn-deco: var(--main-bg);
    --btn-txt: #FFFFFF;
    --btn-border-radius: 10px;
    --btn-radius: 10px;
    --btn-font: 20px;
    --btn-height: 48px;
    --btn-mobile-height: 35px;
    --btn-width: 280px;

    //error modal
    --errorModal-bg: #FDF0E3;
    --errorModal-border: 1px solid #707070;
    --errorModal-title-txt: #5F5D60;
    --errorModal-content-txt: #5F5D60;
    --errorModal-btn-txt: #ffffff;
    --errorModal-btn-bg: linear-gradient(180deg, #FC8165 0%, #FC516A 100%)
}
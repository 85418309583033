@import '../../../resources/theme/Common.scss';

.movie-sticky-header {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1;
}

.movie-render-container {
  height: 186.88px;
  width: 97%;
  margin-bottom: 32.2px;
  display: flex;
  flex-direction: row;
}

.movie-poster {
  width: 146px;
  box-shadow: $main-shadow;
}

.movie-content {
    flex: 1;
  align-self: flex-end;
  height: auto;
  box-shadow: $main-shadow;
  background: $movie-content-bg;
  padding: 16px;
  min-height: 140px;
  max-height: 186px;
}

.movie-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  color: $movie-title;
}

.movie-date {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 8px;
  color: $movie-date;
}

.movie-imdb {
  margin-top: 33px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
  color: $movie-imdb;
}

.movie-imdb-title {
  margin-right: 6px;
  color: $movie-imdb-title;
}

.movie-imdb-rate {
  color: $movie-imdb-rate;
}

.movie-close-container {
  height: 48px;
  width: 48px;
  position: absolute;
  top: -20px;
  right: -20px;
  cursor: pointer;
}

.movie-close {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.movie-popup-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.movie-popup-style {
  width: 100%;
  background: $movie-content-bg;
  padding: 64px 32px 63px 32px;
  position: relative;
  height: 80vh;
  overflow-x: auto;
}

.movie-top {
  display: flex;
  flex-direction: row;
  height: auto;
}

.movie-popup-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 8px;
  color: $movie-popup-title
}

.movie-popup-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-left: 16px;
  width: 100%;
}

.movie-popup-imdb {
  margin-top: 25px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex-direction: row;
}

.movie-btn-container {
  width: 100%;
  margin-top: 42px;
}

.movie-btn-style {
  height: 40px;
  width: 100%;
  border-radius: 24px;
  box-shadow: none;
}

.movie-btn-bg {
  background: $movie-btn-bg;
}

.movie-btn-txt {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $movie-btn-txt;
}

.movie-btm {
  margin-top: 32px;
  background: $movie-detail-bg;
  color: $movie-btm-txt;
  padding: 5px 20px 0px 30px;
  font-size: 14px;
  line-height: 1.58;
}

.movie-btm-container {
  padding-right: 10px;
}

.movie-1st-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 10px;
  border-right: $movie-bot-border;
  border-top: $movie-bot-border;
  padding-top: 15px;
}

.movie-popup-rate-imdb {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.movie-popup-rate-rot {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-left: $movie-bot-border;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.movie-popup-rate-meta {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-left: $movie-bot-border;
  padding-left: 30px;
  padding-right: 30px;
  text-align: center;
}

.movie-popup-rate-title {
  color: $movie-rate-blue;
  font-weight: 400;
}

.movie-2nd-row {
  padding: 15px 20px 15px 10px;
  border-top: $movie-bot-border;
  border-right: $movie-bot-border;
}

.movie-google-rate {
  margin-bottom: 5px;
}

.movie-google-users {
  color: $movie-google-users-txt;
}

.movie-3rd-row {
  padding: 15px 20px 15px 10px;
  border-top: $movie-bot-border;
  border-right: $movie-bot-border;
}

.movie-details {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.movie-details-desc {
  color: $movie-desc;
}

.movie-details-title {
  margin-right: 6px;
  font-weight: bold;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
}

@media screen and (max-width: 280px), screen and (max-height: 500px) {
}

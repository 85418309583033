@import '../../../resources/theme/Common.scss';

.modal {
  position: fixed;
  inset: 0; /* inset sets all 4 values (top right bottom left) much like how we set padding, margin etc., */
  // background: $plane-bg;
  // backdrop-filter: $plane-blur;
  display: flex;
  overflow: hidden;
  z-index: 9999;
  animation: modalAnim 0.5s ease-out;
}

.modal-content {
  width: 100%;
  height: 100%;
  padding: 40px 20px 20px;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 430px), screen and (max-height: 500px) {
  .modal-content {
    justify-content: flex-start;
  }
}

@keyframes modalAnim {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@import '../../../resources/theme/Common.scss';

.porn-sticky-header {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 1;
}
  
.porn-render-container {
    height: 186.88px;
    width: 97%;
    margin-bottom: 32.2px;
    display: flex;
    flex-direction: row;
}
  
.porn-poster {
    width: 146px;
    height: 100%;
    box-shadow: $main-shadow;
}
  
.porn-content {
    align-self: flex-end;
    width: 100%;
    height: auto;
    box-shadow: $main-shadow;
    background: $porn-content-bg;
    padding: 10px;
    min-height: 140px;
    max-height: 186px;
}
  
.porn-desc-container {
    flex-direction: row;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
  
.porn-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: $porn-title;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}
  
.porn-date {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $porn-date;
    margin-bottom: 8px;
}
  
.porn-desc {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $porn-desc;
    margin-bottom: 5px;
}
  
.porn-play-icon {
    height: 55px;
    width: 55px;
}
  
@media screen and (max-width: 430px), screen and (max-height: 500px) {
    .porn-render-container {
      height: 128px;
    }
  
    .porn-content {
      align-self: flex-end;
      width: 322px;
      height: auto;
      box-shadow: $main-shadow;
      background: $porn-content-bg;
      padding: 10px;
      min-height: 104px;
      max-height: 120px;
    }
  
    .porn-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
    }
  
    .porn-date {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  
    .porn-desc {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }
  
    .porn-play-icon {
      height: 44px;
      width: 44px;
    }
}
  
@import '../../../resources/theme/Common.scss';

.errorModal-main-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorModal-container {
    background: $errorModal-bg;
    border: $errorModal-border;
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    border-radius: 25px;
    padding: 72px 40.5px;
}

.errorModal-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 39px;
    text-align: center;
    margin-bottom: 8px;
    color: $errorModal-title-txt;
    word-wrap: break-word;
    white-space: wrap;
}
  
.errorModal-content {
    padding-bottom: 32px;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: $errorModal-content-txt;
}

.errorModal-btn-container {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
  }
  
  .errorModal-btn {
    cursor: pointer;
  }
  
  .errorModal-btn-txt {
    color: $errorModal-btn-txt !important;
  }

  .errorModal-btn-bg {
    background: $errorModal-btn-bg;
  }


@media screen and (max-width: 430px), screen and (max-height: 500px) {
    .errorModal-container {
        padding: 30px 20px;
    }
    .errorModal-title {
      font-size: min(4.6838vw, 20px);
      font-weight: 700;
      line-height: 24px;
      text-align: center;
    }
  
    .errorModal-content {
      font-size: min(3.2787vw, 14px);
      font-weight: 400;
      line-height: 17px;
      text-align: center;
    }
  
}
  
.theme-greenMode-default {
    // Common
    --main-shadow: 4px 4px 4px rgba(0, 0, 0, 0.75);
    --noData-txt: #000000;
    
    // CPListTab
    --listTab: #1F2B27;
    --list-txt: #ffffff;
    --list-unSelect-text: #707070;
    --list-underline: #FBE6AE;

    //#region =====> Porn
    --porn-content-bg: linear-gradient(93deg, #FBE6AE 0.48%, #795222 100.37%);
    --porn-title: #000000;
    --porn-date: #000000;
    --porn-desc: #000000;
    //#endregion

    //#region =====> Movie
    --movie-content-bg: linear-gradient(90deg, #445D4F 0%, #24372E 100%);
    --movie-imdb-rate: #FBE6AE;
    --movie-btn-bg: #FBE6AE;
    --movie-btn-txt: #202124;
    --movie-detail-bg: #ffffff;
    --movie-bot-border: 1px solid rgba(0, 0, 0, 0.12);
    --movie-rate-blue: #1a0dab;
    --movie-google-users-txt: #70757a;
    --movie-desc: #4d5156;
    //#endregion

    //Plane
    --plane-bg: rgba(0, 0, 0, 0.25);
    --plane-blur: blur(10px);

    // CPButton
    --btn-main: linear-gradient(to right, #350bc8, #8633c3, #b72dbc);
    --btn-sub: linear-gradient(to right, #d7437c, #f6b56b);
    --btn-disabled: #565A49;
    --btn-disabled-border: none;
    --btn-disabled-txt: #1C2623;
    --btn-shadow: var(--main-shadow);
    --btn-deco: var(--main-bg);
    --btn-txt: #131617;
    --btn-border-radius: 10px;
    --btn-radius: 10px;
    --btn-font: 20px;
    --btn-height: 48px;
    --btn-mobile-height: 35px;
    --btn-width: 280px;

    //error modal
    --errorModal-bg: #FDF0E3;
    --errorModal-border: 1px solid #707070;
    --errorModal-title-txt: #5F5D60;
    --errorModal-content-txt: #5F5D60;
    --errorModal-btn-txt: #ffffff;
    --errorModal-btn-bg: linear-gradient(180deg, #FC8165 0%, #FC516A 100%)
}
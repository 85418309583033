@import '../../../resources/theme/Common.scss';

.tab-list-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  background: $listTab;
  margin: 0 0 32px 0;
  height: 70px;
}

.tab-rest-container::-webkit-scrollbar {
  display: none;
}

.tab-rest-container {
  display: flex;
  height: 100%;
  overflow-x: scroll;
  flex-direction: row;
  width: 80%;
  padding-left: 30px;
}

.tab-all {
  padding: 0 20px 0 0px !important;
}

.tab-list-btn {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
  text-align: center;
  flex-direction: column;
  padding-right: 40px;
}

.tab-list-txt-selected {
  font-weight: bold;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: $list-txt;
  text-align: center;
  white-space: nowrap;
}

.tab-list-txt-unSelect {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: $list-unSelect-text;
  white-space: nowrap;
}

.tab-list-underline-selected {
  width: 50px;
  height: 6px;
  background: $list-underline;
  position: absolute;
  bottom: 5px;
  border-radius: 10px;
}

.tab-list-style {
  width: 100%;
  height: 60px;
  flex-direction: row;
  display: flex;
}

.tab-date-container {
  height: 100%;
  max-height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  right: 0;
}

// if small than iphone 4
@media screen and (max-width: 384px) {
}

/* small device */
@media screen and (max-width: 430px), screen and (max-height: 500px) {

}

/* height small than width*/
@media screen and (min-height: 264px) and (min-width: 600px) {
}

/* landscape*/
@media screen and (max-height: 500px) {
}

@import './resources/theme/Common.scss';
@import './resources/theme/theme-default.scss';
@import './resources/theme/theme-light.scss';
@import './resources/theme/theme-brownMode-default.scss';
@import './resources/theme/theme-greenMode-default.scss';
@import './resources/theme/theme-goldMode-default.scss';
@import './resources/theme/theme-purpleMode-default.scss';
@import './resources/theme/theme-orangeMode-default.scss';
@import './resources/theme/theme-blueMode-default.scss';
@import './resources/theme/theme-blackGoldMode-default.scss';

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
}

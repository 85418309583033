// Common
$main-shadow: var(--main-shadow);
$noData-txt: var(--noData-txt);

// CPListTab
$listTab: var(--listTab);
$list-txt: var(--list-txt);
$list-unSelect-text: var(--list-unSelect-text);
$list-underline: var(--list-underline);

//#region =====> Porn
$porn-content-bg: var(--porn-content-bg);
$porn-title: var(--porn-title);
$porn-date: var(--porn-date);
$porn-desc: var(--porn-desc);
//#endregion

//#region =====> Movie
$movie-content-bg: var(--movie-content-bg);
$movie-imdb-rate: var(--movie-imdb-rate);
$movie-btn-bg: var(--movie-btn-bg);
$movie-btn-txt: var(--movie-btn-txt);
$movie-detail-bg: var(--movie-detail-bg);
$movie-bot-border: var(--movie-bot-border);
$movie-rate-blue: var(--movie-rate-blue);
$movie-google-users-txt: var(--movie-google-users-txt);
$movie-desc: var(--movie-desc);
$movie-title: var(--movie-title);
$movie-date: var(--movie-date);
$movie-imdb: var(--movie-imdb);
$movie-popup-title: var(--movie-popup-title);
$movie-imdb-title: var(--movie-imdb-title);
$movie-btm-txt: var(--movie-btm-txt);
//#endregion

//Plane
$plane-bg: var(--plane-bg);
$plane-blur: var(--plane-blur);

// CPButton
$btn-main: var(--btn-main);
$btn-sub: var(--btn-sub);
$btn-disabled: var(--btn-disabled);
$btn-disabled-border: var(--btn-disabled-border);
$btn-disabled-txt: var(--btn-disabled-txt);
$btn-shadow: var(--btn-shadow);
$btn-deco: var(--btn-deco);
$btn-txt: var(--btn-txt);
$btn-border-radius: var(--btn-border-radius);
$btn-radius: var(--btn-radius);
$btn-font: var(--btn-font);
$btn-height: var(--btn-height);
$btn-mobile-height: var(--btn-mobile-height);
$btn-width: var(--btn-width);

//error modal
$errorModal-bg: var(--errorModal-bg);
$errorModal-border: var(--errorModal-border);
$errorModal-title-txt: var(--errorModal-title-txt);
$errorModal-content-txt: var(--errorModal-content-txt);
$errorModal-btn-txt: var(--errorModal-btn-txt);
$errorModal-btn-bg: var(--errorModal-btn-bg);